export enum BriefPanelName {
    DEFAULT,
    ELEKTROMIZ_SOLAR,
    SMARTVENT,
    ELEKTROMIZ_MINISTER_PID,
    ELEKTROMIZ_MINISTER_PELET,
    ELEKTROMIZ_MINISTER_MAX,
    ELEKTROMIZ_MINISTER_ELEKTR,
    ELEKTROMIZ_LFP_WSTER,
    ELEKTROMIZ_WIND_TURBINE_CONTROL,
    ELEKTROMIZ_WIND_TURBINE_CONTROL_GONDOLA,
    HABA_MULTIBIO_2000,
    HABA_MULTIBIO_3100,
    HABA_MULTIBIO_3200,
    ELEKTROMIZ_COBRA,
    LFP_EPOMP,
    TWEETOP,
    TWEETOP_OP,
    TWEETOP_POMPA_V2,
}
